import * as React from "react"
import { Link } from "gatsby"

export default function WritingReading() {
  return(
    <div className="grid grid-cols-2 gap-x-10 md:gap-x-20">

      <div className="space-y-7">
        <h1 className="text-3xl font-bold">
          writing
        </h1>

        <div className="space-y-1">
          <div className="text-xl font-bold mb-3">
            essays
          </div>

          <div className="flex justify-between gap-x-8">
            <Link to="/writing/techforgood" className="hover:underline">
              Reflections on "Tech for Good"
            </Link>
            <div className="hidden md:flex text-gray-500">
              11/2/2023
            </div>
          </div>

          <div className="flex justify-between gap-x-8">
            <a href="https://juliacamacho.github.io/food-delivery-ethics/" className="hover:underline">
              When Free Lunch Goes Wrong: The Ethics of Uber Eats, DoorDash, and the Food Delivery Industry
            </a>
            <div className="hidden md:flex text-gray-500">
              8/19/2022
            </div>
          </div>

          <div className="flex justify-between gap-x-8">
            <Link to="/writing/bereal" className="hover:underline">
              Data Privacy and Social Authenticity in the Age of BeReal
            </Link>
            <div className="hidden md:flex text-gray-500">
              4/21/2022
            </div>
          </div>

          <div className="flex justify-between gap-x-8">
            <Link to="/writing/liberal-arts-STEM" className="hover:underline">
              Rooting STEM education in liberal arts principles
            </Link>
            <div className="hidden md:flex text-gray-500">
              7/23/2021
            </div>
          </div>

        </div>

        <div className="space-y-1">
          <div className="text-xl font-bold mb-3">
            notes
          </div>

          <div className="flex justify-between space-x-8">
            <Link to="/writing/classes" className="hover:underline">
              List of classes I've taken at MIT
            </Link>
            <div className="hidden md:flex text-gray-500">
              last updated spring 2024
            </div>
          </div>

          <div className="flex justify-between">
            <Link to="/writing/food-for-thought" className="hover:underline">
              Current food for thought
            </Link>
            <div className="hidden md:flex text-gray-500">
              1/25/22
            </div>
          </div>
        </div>

        <div className="space-y-1">
          <div className="text-xl font-bold mb-3">
            other
          </div>

          <div className="flex justify-between space-x-8">
            <a href="https://juliacamacho.github.io/6c35_final/" className="hover:underline">
              Prioritizing Municipalities for Compliance With the MBTA Communities Act (Interactive Data Visualization)
            </a>
            <div className="hidden md:flex text-gray-500">
              5/13/2024
            </div>
          </div>

          <div className="flex justify-between space-x-8">
            <a href="https://t4america.org/2023/09/25/beyond-the-pump/" className="hover:underline">
              Beyond the Pump: Evaluating Fresh Approaches to Transportation Funding
            </a>
            <div className="hidden md:flex text-gray-500">
              9/25/2023
            </div>
          </div>

          <div className="flex justify-between">
            <a href="https://t4america.org/2023/07/19/setting-priorities-at-future-of-transportation-caucus-roundtable/" className="hover:underline">
              Setting Priorities at the Future of Transportation Congressional Caucus Roundtable
            </a>
            <div className="hidden md:flex text-gray-500">
              7/19/2023
            </div>
          </div>
        </div>

      </div>

      <div className="space-y-7">
        <h1 className="text-3xl font-bold">
          (& reading)
        </h1>

        <div className="space-y-1">
          <div>
            <Link to="/reading/favorites" className="hover:underline">
              Favorite books
            </Link>
          </div>
          <div>
            <Link to="/reading/boston-bookstores" className="hover:underline">
              Bookstores of Boston
            </Link>
          </div>
        </div>

      </div>

    </div>
  )
}