import * as React from "react"
import knowsy from '../images/knowsy.png'
import accecity from '../images/accecity.png'
import grassrootsgov from '../images/gg_logo3.png'
import nofall from '../images/nofall.png'
import hrfitness from '../images/36hrfitness.png'
import sparkstem from '../images/sparkstem.png'

export default function Projects() {
  return(
    // <div className="bg-slate-50 rounded-lg px-8 py-6">
      <div className="space-y-8">
        <h1 className="text-3xl font-bold">
          projects
        </h1>
        <div className="flex flex-col space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-7">
          <a href="https://knowsy-frontend-brian-zhus-projects.vercel.app">
            <div className="bg-white shadow hover:shadow-lg rounded-lg">
            <div className="flex justify-center bg-sky-50 border-b border-gray-100 rounded-t-lg py-5 md:py-0">
                <img src={knowsy} className="rounded-t-lg h-20 md:h-40" />
              </div>
              <div className="px-8 py-6">
                <div className="text-xl font-semibold mb-1">
                  Knowsy
                </div>
                <div className="text-sm mb-1">
                  Fall 2023, with Lucy Kim, Brian Zhu, and Yuebin Huang
                </div>
                <div className="">
                  A location-based platform for neighborhood residents to track, share, and discuss city development projects in their community.
                </div>
              </div>
            </div>
          </a>
          <a href="https://github.com/juliacamacho/AcceCity">
            <div className="bg-white shadow hover:shadow-lg rounded-lg">
            <div className="flex justify-center bg-sky-500 border-b border-gray-100 rounded-t-lg py-5 md:py-0">
                <img src={accecity} className="rounded-t-lg h-20 md:h-36" />
              </div>
              <div className="px-8 py-6">
                <div className="text-xl font-semibold mb-1">
                  AcceCity
                </div>
                <div className="text-sm mb-1">
                  Spring 2022, with Steven Yao, Kevin Yao, and Pratyush Mallick
                </div>
                <div className="">
                  An AI-powered mapping platform that helps local governments and city planners improve urban accessibility (built at TreeHacks 2022; special prize winner).
                </div>
              </div>
            </div>
          </a>
          <a href="https://grassrootsgov.org">
            <div className="bg-white shadow hover:shadow-lg rounded-lg">
              <div className="flex justify-center align-center bg-blue-50 border-b border-gray-100 px-12 py-10 sm:h-20 md:h-40 rounded-t-lg">
                <img src={grassrootsgov} className="rounded-t-lg h-10 md:h-16" />
              </div>
              <div className="px-8 py-6">
                <div className="text-xl font-semibold mb-1">
                  GrassrootsGov
                </div>
                <div className="text-sm mb-1">
                  2020–22, with Pratyush Mallick, Gary Wu, and others
                </div>
                <div className="">
                  A platform for everyday political engagement and activism that centers accountability, transparency, and community.
                </div>
              </div>
            </div>
          </a>
          <a href="https://github.com/juliacamacho/nofall">
            <div className="bg-white shadow hover:shadow-lg rounded-lg">
              <div className="flex justify-center bg-gradient-to-r from-noFallPurpleL to-noFallPurpleR border-b border-gray-100 rounded-t-lg py-5 md:py-0">
                <img src={nofall} className="rounded-t-lg h-20 md:h-40" />
              </div>
              <div className="px-8 py-6">
                <div className="text-lg font-semibold mb-1">
                  NoFall
                </div>
                <div className="text-sm mb-1">
                  Spring 2021, with Eric Zhang, Kevin Yao, and Steven Yao
                </div>
                <div>
                  A remote caregiving system with ambient activity monitoring, risk assessments, fall detection, and more (built at TreeHacks 2021; special prize winner).
                </div>
              </div>
            </div>
          </a>
          <a href="https://github.com/juliacamacho/36hrfitness">
            <div className="bg-white shadow hover:shadow-lg rounded-lg">
              <div className="flex justify-center bg-36hrMauve border-b border-gray-100 rounded-t-lg py-5 md:py-0">
                <img src={hrfitness} className="rounded-t-lg h-20 md:h-40" />
              </div>
              <div className="px-8 py-6">
                <div className="text-lg font-semibold mb-1">
                  36 Hour Fitness
                </div>
                <div className="text-sm mb-1">
                  Fall 2020, with Kevin Meng, Brandon Wang, and Nihar Annam
                </div>
                <div>
                  An AI-powered virtual personal trainer that enhances the quality of home workouts (built at HackMIT 2020; grand prize winner).
                </div>
              </div>
            </div>
          </a>
          <a href="https://sparkstem.org">
            <div className="bg-white shadow hover:shadow-lg rounded-lg">
              <div className="flex justify-center bg-sparkSTEMGray border-b border-gray-100 rounded-t-lg py-1 md:py-0">
                <img src={sparkstem} className="rounded-t-lg h-28 md:h-40" />
              </div>
              <div className="px-8 py-6">
                <div className="text-lg font-semibold mb-1">
                  SparkSTEM
                </div>
                <div className="text-sm mb-1">
                  2018–20, with Anirudh Sudarshan, Pranav Nathan, Emily Zhou, and Risha Valera
                </div>
                <div>
                  Founded SparkSTEM, a 501(c)(3) nonprofit mentoring students in research competitions and raising money for educational charities.
                </div>
              </div>
            </div>
          </a>
        {/* </div> */}

      </div>
    </div>
  )
}
